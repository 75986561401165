<script setup lang="ts">
import { ref } from 'vue';
import { detect } from 'detect-browser';

const browser = detect();
const isSafari = ref<boolean>(browser?.name === 'safari' || browser?.name === 'ios' || browser?.name === 'crios');
</script>

<template>
  <main>
    <h1>
      <span>Coming in January 2024</span>
    </h1>
    <img v-if="isSafari" src="@/assets/banner.gif" alt="banner">
    <video v-else src="@/assets/banner.mp4" playsinline autoplay loop muted />
  </main>
</template>

<style scoped lang="scss">
@import 'src/styles/mixins';

main {
  position: relative;

  h1 {
    @include typo-h1;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    text-transform: uppercase;
    color: $color-white;
    background: rgba(0, 0, 0, 0.2);
  }

  img, video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}
</style>
